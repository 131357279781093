













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
    MaintenanceDialogComponent: () => import('./../components/dialog/MaintenanceDialog.component.vue'),
    InventoryDialogComponent: () => import('./../components/dialog/InventoryDialog.component.vue'),
  }
})
export default class MapInfoComponent extends Vue {

  @Prop({ default: [53.073635, 8.806422] })
  public center!: Array<number>;

  private url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  public zoom = 7;
  public markerSize = [32, 37];

  public zoomUpdated(zoom: number) {
    this.zoom = zoom;
    if (zoom <= 14) {
      this.markerSize = [16, 20];
    } else {
      this.markerSize = [32, 37];
    }
  }
}

